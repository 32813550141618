<template>
	<div class="homepage">
		<div class="imagebox fade" :style="{'display': slide1Active ? 'block' : 'none'}">
			<div class="background-img1"></div>
			<div class="landing-image">
				<h1 style="color:white" class="headertext">STEM CAREER DAY {{$stemYear}}</h1>
				<!-- <h2 style="color:white" class="header-sub-text2">Registration Now Open!</h2> -->
			</div>
		</div>
		<div class="imagebox fade" :style="{'display': slide2Active ? 'block' : 'none'}">
			<div class="background-img2"></div>
			<div class="landing-image">
				<h1 style="color:white" class="headertext">STEM CAREER DAY {{$stemYear}}</h1>
				<!-- <h2 style="color:white" class="header-sub-text2">Registration Now Open!</h2> -->
			</div>
		</div>
		<div class="imagebox fade" :style="{'display': slide3Active ? 'block' : 'none'}">
			<div class="background-img3"></div>
			<div class="landing-image">
				<h1 style="color:white" class="headertext">STEM CAREER DAY {{$stemYear}}</h1>
				<!-- <h2 style="color:white" class="header-sub-text2">Registration Now Open!</h2> -->
			</div>
		</div>
		<div class="imagebox fade" :style="{'display': slide4Active ? 'block' : 'none'}">
			<div class="background-img4"></div>
			<div class="landing-image">
				<h1 style="color:white" class="headertext">STEM CAREER DAY {{$stemYear}}</h1>
				<!-- <h2 style="color:white" class="header-sub-text2">Registration Now Open!</h2> -->
			</div>
		</div>
		<div class="text-banner gr-bak text-center">
			<h2>STEM Career Day {{$stemYear}} Details</h2>
		</div>

		<div class="row home-event-boxes">
			<div class="text-center col-12 col-md-4">
				<div class="calendar-text">
					<p class="heading">Middle School</p>
					<p>Firestone CLC </p>
					<p>April 5, 2025</p>
					<p>8:00 am-2:30 pm</p>
					<p>Grades 6-8</p>
					<!--<p v-if="$isMSIndRegActive"><b><router-link :to="{ name: 'register' }" class="home-page-link">REGISTER HERE</router-link></b></p>-->
					<!-- <p v-if="$isMSGroupRegActive">Group: <b><router-link :to="{ name: 'register' }" class="home-page-link">REGISTER HERE</router-link></b></p> -->
					<!-- <p v-else><b>Registration Coming Soon</b></p> -->
					<!--<p v-else><b>Registration Closed</b></p>-->
					<!-- <p><b><router-link :to="{ name: 'map' }" class="home-page-link">Directions & Schedule</router-link></b></p> -->
					<p><b><router-link :to="{ name: 'contact' }" class="home-page-link">Information about walk-in registration</router-link></b></p>
				</div>
			</div>

			<div class="circle-padding text-center col-12 col-md-4">
				<div class="calendar-text">
					<p class="heading">High School</p>
					<p>The University of Akron</p>
					<p>Feb. 8, 2025</p>
					<p>8:00 am-12:00 pm</p>
					<p>Grades 9-12</p>
					<!--<p v-if="$isHSIndRegActive"><b><router-link :to="{ name: 'register' }" class="home-page-link">REGISTER HERE</router-link></b></p>-->
					<!-- <p v-else><b>Registration Coming Soon</b></p> -->
					<!--<p v-else><b>Registration Closed</b></p>-->
					<!-- <p>Walk-Ins Welcome</p> -->
					<!-- <p><b><router-link :to="{ name: 'map' }" class="home-page-link">Directions & Schedule</router-link></b></p> -->
					<p><b><router-link :to="{ name: 'contact' }" class="home-page-link">Information about walk-in registration</router-link></b></p>
				</div>
			</div>

			<div class="text-center col-12 col-md-4">
				<div class="calendar-text">
					<p class="heading">Rube Goldberg</p>
					<p>Create a Team to Win Grants and Prizes</p>
					<p>Middle School Challenge - <b><router-link :to="{name: 'grants'}" class="home-page-link">APPLY HERE</router-link></b></p>
					<p>High School Challenge - <b><router-link :to="{name: 'grants'}" class="home-page-link">APPLY HERE</router-link></b></p>
					<!-- <p>High School Challenge - <b>Applications Closed</b></p> -->
					<p class="heading">Scholarships</p>
					<p>Open to High School Seniors</p>
					<p v-if="$isScholarshipRegActive"><b><router-link :to="{name: 'scholarship-details'}" class="home-page-link">APPLY HERE</router-link></b></p>
					<p v-if="!$isScholarshipRegActive"><b>Applications Closed</b></p>
					<!-- <p v-if="!$isScholarshipRegActive"><b>Application Opening Soon</b></p> -->
				</div>
			</div>
		</div>

		<div class="text-banner gr-bak text-center">
			<h2>Our Sponsors</h2>
		</div>
		<b-row class="company-logos-wrapper">
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/university-of-akron.png" alt="The University of Akron Logo">
			</b-col>
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/smithers-logo.png" alt="Smithers Logo">
			</b-col>
		</b-row>
		<b-row class="company-logos-wrapper">
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/schaeffler-logo.png" alt="Schaeffler Logo">
			</b-col>
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/gpd-group-logo-2025.png" alt="GPD Group Logo">
			</b-col>
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/bescast-logo.png" alt="Bescast Logo">
			</b-col>
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/carmeuse-logo.png" alt="Carmeuse Logo">
			</b-col>
			<b-col class="company-logos text-center ua-logo" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
				<img src="../assets/img/logos/ark-logo.png" alt="Ark Logo">
			</b-col>
		</b-row>
	</div>
</template>

<style scoped>

	.imagebox {
		height: 600px;
		position: relative;
		opacity: 1;
	}

	/* Fading animation */
	.fade {
		animation-name: fade;
		animation-duration: 5s;
	}

	@keyframes fade {
		from {opacity: .65}
		to {opacity: 1}
	}

	.background-img1, .background-img2, .background-img3, .background-img4 {
		background-size: cover;
		background-position: center;
		height: 600px;
		width: 100%;
		opacity: 1;
	}

	.background-img1 {
		background-image: url(../assets/img/home/slideshow1.jpg);
	}
	.background-img2 {
		background-image: url(../assets/img/home/slideshow2.jpg);
	}
	.background-img3 {
		background-image: url(../assets/img/home/slideshow3.jpg);
	}
	.background-img4 {
		background-image: url(../assets/img/home/slideshow4.jpg);
	}
</style>

<script>
	export default {
		data: () => ({
			slideIndex: -1,
			slide1Active: false,
			slide2Active: false,
			slide3Active: false,
			slide4Active: false,
		}),

		mounted() {
			this.showSlides();
		},

		methods: {
			showSlides: function() {
				this.slide1Active = false;
				this.slide2Active = false;
				this.slide3Active = false;
				this.slide4Active = false;
				this.slideIndex++;
				if (this.slideIndex > 3) {
					this.slideIndex = 0;
				}
				if(this.slideIndex === 0) {
					this.slide1Active = true;
				} else if(this.slideIndex === 1) {
					this.slide2Active = true;
				} else if(this.slideIndex === 2) {
					this.slide3Active = true;
				} else if(this.slideIndex === 3) {
					this.slide4Active = true;
				}
				setTimeout(this.showSlides, 5000);
			}

		}
	}
</script>